<template>
  <div class="column-center full-width" style="background-color: #2588ce">
    <div class="news-content-container column">
      <div class="news-title">{{ newsDetail.title }}</div>
      <p class="news-desc multi-text">{{ newsDetail.short_desc }}</p>
      <div class="news-time">{{ newsDetail.d_time_format }}</div>
      <div class="news-content ql-editor" v-html="newsDetail.content"></div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      newsDetail: {
        news_id: 0,
        title: "",
        short_desc: "",
        content: "",
        d_time: "",
        d_time_format: "",
        img_url: "",
        orderby: 0,
      },
    };
  },
  mounted() {
    this.newsDetail.news_id = this.$router.currentRoute.params.news_id;

    this.getDetail();
  },
  methods: {
    ...mapActions({
      newsGetDetailAction: "newsGetDetail",
    }),
    getDetail() {
      this.newsGetDetailAction(this.newsDetail)
        .then((res) => {
          this.newsDetail = res;
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.news-content-container {
  width: 900px;
  padding: 50px;
  background-color: white;
}
.news-title {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #282828;
  letter-spacing: 0;
  margin-bottom: 15px;
  align-self: center;
}
.news-desc {
  width: 1200px;
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #282828;
  letter-spacing: 0;
  margin-bottom: 25px;
}
.news-time {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #282828;
  letter-spacing: 0;
}
.news-content {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0px;
}
</style>